var lpTag = lpTag || {};

(function ($) {
  Drupal.behaviors.livechatProductCardV1 = {
    attach: function (context) {
      var loaded = false;
      lpTag.hooks = lpTag.hooks || [];

      function setColor(node) {
        var attributesList = $("[aria-label='BrrFen-2']", node);

        if (attributesList.length > 0) {
          $.each(attributesList, function (index, el) {
            var $element = $(el);

            if (!el.style.backgroundColor) {
              var color = $element.text();
              $element.css('background-color', color);
            }
          });
        }
      }

      function checkClass(className) {
        return (
          className === 'BrnDej-1' ||
          className === 'BrrFen-1' ||
          className === 'BrrFen-2' ||
          className === 'BrrFen-3' ||
          className === 'BrrFen-4' ||
          className === 'BrrFen-5' ||
          className === 'BrnDej-2' ||
          className === 'BrnDej-3' ||
          className === 'XrfY-1' ||
          className === 'XrfY-2' ||
          className === 'XrfY-3' ||
          className === 'Rc5Dej-1' ||
          className === 'qRnRDej-1'
        );
      }

      function cleanAttributes(node) {
        var $node = $(node);
        var $titleAttributes = $('[title]', $node);

        if ($titleAttributes.length > 0) {
          $.each($titleAttributes, function (index, el) {
            var $element = $(el);
            var className = $element.attr('title');

            if (checkClass(className)) {
              $element.removeAttr('title');
              $element.addClass(className);
              if (!$node.hasClass('card')) {
                $node.addClass('card');
              }
            }
          });
        }
      }

      if (lpTag.hooks !== undefined) {
        lpTag.hooks.push({
          name: 'AFTER_GET_LINES',
          callback: function () {
            if (!loaded) {
              var observer = new MutationObserver(function (mutationsList) {
                $.each(mutationsList, function (key, mutation) {
                  $.each(mutation.addedNodes, function (index, addedNode) {
                    var strContent = $('.lp-json-pollock', addedNode)[0];

                    if (strContent) {
                      setColor(strContent);
                      cleanAttributes(strContent);
                    }
                  });
                });
              });

              observer.observe($("[data-lp-point='lines_area']", context)[0], {
                subtree: false,
                childList: true
              });
              loaded = true;
            }
          }
        });
      }
    }
  };
})(jQuery);
